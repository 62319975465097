<template>
  <div id="list-job-modal" :class="{ 'modal-expanded': modal_expanded }">
    <div class="columns m-0 height-100">
      <div class="column is-11">
        <div v-if="!modal_expanded">
          <p>{{ $t("warehouses") }} ({{ this.$parent.total_warehouses }})</p>
          <p>{{ $t("jobs_warehouse") }} ({{ this.$parent.total_jobs }})</p>
        </div>
        <div v-else>
          <ul>
            <li
              v-for="warehouse in this.$parent.all_jobs"
              :key="warehouse.warehouse"
              class="3"
            >
              <span class="is-block px-3 pt-3 has-border-bottom-orange-1 mb-2">
                <b style="font-size: 18px !important">{{
                  warehouse.warehouse_object.Caption
                }}</b>
                <small
                  v-if="jobs_date_check(warehouse.jobs)"
                  class="has-text-danger-dark is-pulled-right"
                >
                  <i
                    class="fas fa-exclamation-triangle has-text-danger ml-2"
                  ></i>
                  {{
                    $t("there_is_a_problem_with_dates", {
                      number: jobs_date_check(warehouse.jobs),
                    })
                  }}
                </small>
              </span>
              <ul class="">
                <li
                  v-for="(job, index) in warehouse.jobs"
                  :key="index"
                  class="ml-2"
                >
                  <i
                    v-if="!$moment(job.starts).isSameOrAfter(midnight)"
                    title="Dates cannot be in the past!"
                    class="fas fa-exclamation-triangle has-text-danger mr-2"
                  ></i>

                  <i class="far fa-calendar-alt is-text-palete-green-2"></i>
                  <span class="ml-3 is-uppercase" style="font-size: 14px">
                    {{ job.starts | customdate }} - {{ job.ends | customdate }}
                  </span>
                  <ul>
                    <!-- <nav v-for="(material, index1) in job.materials" :key="index1"  class="level ml-4 mb-0">
                                            <div class="level-left">
                                                <div class="level-item">
                                                    <p class="px-2 py-1">{{index1 + 1}}</p>
                                                </div>
                                                <div class="level-item">
                                                    <p class="px-2 py-1 has-text-weight-bold"> {{material.Caption}}</p>
                                                </div>
                                                <div class="level-item">
                                                        <p class="px-2 py-1">{{material.Qty}}</p>
                                                </div>
                                            </div>
                                        </nav> -->
                    <li
                      v-for="(material, index1) in job.materials"
                      :key="index1"
                      class="columns mb-0"
                      style="border-bottom: 1px solid #dedede54"
                    >
                      <div class="column is-1 has-text-right pb-0">
                        {{ index1 + 1 }}
                      </div>
                      <div class="column is-8 pb-0">
                        <b> {{ material.Caption }}</b>
                        <small
                          class="is-block is-italic"
                          v-if="material.linked"
                        >
                          &rdca;
                          {{ material.linked.material_caption }}
                        </small>
                      </div>
                      <div
                        class="column has-text-right is-2 pb-0"
                        v-if="!material.linked"
                      >
                        {{ material.Qty }}
                      </div>
                      <div class="column has-text-right is-2 pb-0" v-else>
                        {{ material.Qty }} ~
                        {{
                          Math.ceil(
                            parseFloat(material.Qty) /
                              parseFloat(material.linked.factor)
                          )
                        }}
                      </div>
                      <div class="column is-1 pb-0">
                        <!-- <a href="" @click.prevent="deleteDate(material.dates, material)"> -->
                        <a
                          href=""
                          @click.prevent="
                            delete_material_pending_job(material, job.hash)
                          "
                        >
                          <i
                            class="fa fa-trash-alt has-text-danger is-pulled-right mr-1"
                          ></i>
                          <!-- {{job.hash}} -->
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <a
            href=""
            @click.prevent="$parent.createJobs"
            class="button material-assigned is-small is-pulled-right mt-3"
            >{{ $t("save_jobs") }}</a
          >
        </div>
      </div>
      <div
        @click="modal_expanded = !modal_expanded"
        class="column is-1 flex-center-center expand-btn-icon"
      >
        <i v-if="!modal_expanded" class="fas fa-angle-up fa-2x"></i>
        <i v-else class="fas fa-angle-down fa-2x"></i>
      </div>
    </div>
    <!-- {{this.$parent.total_jobs}} Jobs -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal_expanded: false,
      midnight: null,
    };
  },
  methods: {
    deleteDate(d, m) {
      // var d = this.$parent.currentDates
      this.$parent.deleteDate(d, m);
    },
    delete_material_pending_job(m, h) {
      this.$fire({
        text: this.$t("are_you_sure_remove_material", { mat: m.Caption }),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          // 1. Make added from 1 to 0
          this.$parent.$parent.jobs = [
            ...this.$parent.$parent.jobs.map((job) => {
              job.materials = job.materials.map((mat) => {
                mat.date_amount = mat.date_amount.map((date) => {
                  console.log("date", date.hash, m.hash, mat);
                  if (date.hash == h) {
                    // console.log('Found date', date)
                    date.added = 0;
                    date.warehouse = {};
                  }
                  return date;
                });
                return mat;
              });
              return job;
            }),
          ];

          // 2. Remove from masterJobMaterials
          this.$parent.$parent.masterJobMaterials = [
            ...this.$parent.$parent.masterJobMaterials.map((aa) => {
              aa.dates = aa.dates.filter((date) => date.hash != h);
              return aa;
            }),
          ];

          //3. If warehouse have no dates -> remove warehouse from masterJobMaterials
          this.$parent.$parent.masterJobMaterials.map((aa) => {
            if (aa.dates.length == 0) {
              this.$parent.$parent.masterJobMaterials =
                this.$parent.$parent.masterJobMaterials.filter((ff) => {
                  return ff.dates.length > 0;
                });
            }
          });

          // toast
          this.$toast(this.$t("cmaterial_removed_success"));
        }
      });
    },
    jobs_date_check(wj) {
      var a = 0;
      wj.map((job) => {
        if (
          !this.$moment(job.starts).isSameOrAfter(
            new Date().setHours(0, 0, 0, 0)
          )
        ) {
          a++;
        }
      });
      return a;
    },
  },
  created() {
    this.midnight = new Date().setHours(0, 0, 0, 0);

    console.log(this.midnight, "midnight");
  },
};
</script>

<style scoped>
.expand-btn-icon {
  background: #0000007a;
  cursor: pointer;
}
.modal-expanded {
  /* height:40vh; */
}
</style>
